import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, Inject } from '@angular/core';
import { TenantService } from '../services/tenant.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Tenant } from '../models/tenant';
import { Unit } from '../models/unit';

@Component({
    selector: 'app-edit-unit.dialog',
    templateUrl: './editUnit.dialog.component.html',
    styleUrls: ['./editUnit.dialog.component.css']
})

export class EditUnitDialogComponent {
    editUnitForm : FormGroup;
    tenantId: string;
    npisRow: string;
    ncpdpIdsRow: string;

    constructor(public dialogRef: MatDialogRef<EditUnitDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: Unit,
        public tenantService: TenantService) {

        this.tenantId = data.tenantId;
        this.npisRow = data.npis?.join(',');
        this.ncpdpIdsRow = data.ncpdpIds?.join(',');

        this.editUnitForm = new FormGroup({
            name: new FormControl(this.data.name,[Validators.required]),
            secondaryName: new FormControl(this.data.secondaryName),
            rak: new FormControl(this.data.rak, [Validators.required]),
            externalId: new FormControl(this.data.externalId),
            ncpdpIds: new FormControl(this.data.ncpdpIds, [Validators.pattern('^(\\d{7}(,){0,1})*$')]),
            npis: new FormControl(this.data.npis, [Validators.pattern('^(\\d{10}(,){0,1})*$')]),
            dea: new FormControl(this.data.dea),
          });
    }

    submit() {
        // emppty stuff
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    public confirmAdd(): void {
        this.sanitizeData();    
        this.tenantService.editUnit(this.data);
    }

    sanitizeData(): void {
        this.data.npis = this.npisRow?.split(',').filter(n => n); 
        this.data.ncpdpIds = this.ncpdpIdsRow?.split(',').filter(n => n);
    }
}
