import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ClientApp } from '../models/clientApp';
import { DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, defer, fromEvent, merge, Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { ClientApplicationService } from '../services/client-application.service';
import { AddClientApplicationDialogComponent } from './addApplication.dialog.component';
import { Tenant } from '../models/tenant';
import { TenantService } from '../services/tenant.service';
import { ToasterService } from '../services/toaster.service';
import { ConfigService } from '../utils/config.service';
import { ClientSecretDialogComponent } from './client-secret.dialog.component';
import { PathService } from '../utils/path.service';
import { ClientInfo } from '../models/clientInfo';
import { Resource } from '../models/resource';
import { DeleteClientComponent } from './delete-client.dialog.component';


@Component({
  selector: 'app-client-applications',
  templateUrl: './client-application-list.component.html',
  styleUrls: ['./client-application-list.component.scss']
})
export class ClientApplicationListComponent implements OnInit, OnDestroy {

  displayedColumns = ['name', 'tenantName', 'unitName', 'resourceName', 'actions'];
  exampleDatabase: ClientApplicationService | null;
  dataSource: ExampleDataSource | null;
  index: number;
  id: string;

  tenants: Tenant[];
  resources: String[];

  selectedClient: ClientInfo;

  destroy$ = new Subject<void>();

  constructor(public httpClient: HttpClient,
              public dialog: MatDialog,
              public dataService: ClientApplicationService,
              public tenantService: TenantService,
              private configService: ConfigService,
              private toasterService: ToasterService,
              private path: PathService) { }

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('filter', { static: true }) filter: ElementRef;

  ngOnInit() {
    this.loadData();
  }

  refresh() {
    this.loadData();
  }

  addNew() {
    const dialogRef = this.dialog.open(AddClientApplicationDialogComponent, {
      autoFocus: false,
      data: {
        clientApp: ClientApp,

        tenantList: this.tenants,
        resourceList: this.resources
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 1) {
        // After dialog is closed we're doing frontend updates
        // For add we're just pushing a new row inside DataService
        this.exampleDatabase.dataChange.value.push(this.dataService.getDialogData());
        this.refreshTable();
      }
    });
    dialogRef.afterClosed().subscribe(() => { this.loadData(); } );
  }

  getInfo(app: ClientApp){
    this.dialog.open(ClientSecretDialogComponent, {
      data : app
    });
  }

  delete(app: ClientApp){
    const dialogRef = this.dialog.open(DeleteClientComponent, {
      data : app
    });
  }

  private refreshTable() {
    // Refreshing table using paginator
    // Thanks yeager-j for tips
    // https://github.com/marinantonio/angular-mat-table-crud/issues/12
    this.paginator._changePageSize(this.paginator.pageSize);
  }

  public loadData() {
    this.dataService.getResources().subscribe((data: Resource[]) => {
      console.log(data);
      this.resources = data.map(o => o.name);
    });

    this.tenantService.getTenants().pipe(takeUntil(this.destroy$)).subscribe(x => this.tenants = x);
    this.exampleDatabase = new ClientApplicationService(this.httpClient, this.configService, this.toasterService, this.path);

    this.dataSource = new ExampleDataSource(this.dataService, this.paginator, this.sort);
    fromEvent(this.filter.nativeElement, 'keyup')
      // .debounceTime(150)
      // .distinctUntilChanged()
      .subscribe(() => {
        if (!this.dataSource) {
          return;
        }
        this.dataSource.filter = this.filter.nativeElement.value;
      });
  }

  ngOnDestroy(): void{
    this.destroy$.next();
    this.destroy$.complete();
  }
}

export class ExampleDataSource extends DataSource<ClientApp> {
  _filterChange = new BehaviorSubject('');

  get filter(): string {
    return this._filterChange.value;
  }

  set filter(filter: string) {
    this._filterChange.next(filter);
  }

  filteredData: ClientApp[] = [];
  renderedData: ClientApp[] = [];

  constructor(public _exampleDatabase: ClientApplicationService,
              public _paginator: MatPaginator,
              public _sort: MatSort) {
    super();
    // Reset to the first page when the user changes the filter.
    this._filterChange.subscribe(() => this._paginator.pageIndex = 0);
  }

  /** Connect function called by the table to retrieve one stream containing the data to render. */
  connect(): Observable<ClientApp[]> {
    // Listen for any changes in the base data, sorting, filtering, or pagination
    const displayDataChanges = [
      this._exampleDatabase.dataChange,
      this._sort.sortChange,
      this._filterChange,
      this._paginator.page
    ];

    this._exampleDatabase.getAll();


    return merge(...displayDataChanges).pipe(map(() => {
      // Filter data
      this.filteredData = this._exampleDatabase.data.slice().filter((clientApp: ClientApp) => {
        const searchStr = (clientApp.id + clientApp.name + clientApp.tenantName + clientApp.unitName).toLowerCase();
        return searchStr.indexOf(this.filter.toLowerCase()) !== -1;
      });

      // Sort filtered data
      const sortedData = this.sortData(this.filteredData.slice());

      // Grab the page's slice of the filtered sorted data.
      const startIndex = this._paginator.pageIndex * this._paginator.pageSize;
      this.renderedData = sortedData.splice(startIndex, this._paginator.pageSize);
      return this.renderedData;
    }
    ));
  }

  disconnect() { }


  /** Returns a sorted copy of the database data. */
  sortData(data: ClientApp[]): ClientApp[] {
    if (!this._sort.active || this._sort.direction === '') {
      return data;
    }

    return data.sort((a, b) => {
      let propertyA: number | string = '';
      let propertyB: number | string = '';

      switch (this._sort.active) {
        case 'id': [propertyA, propertyB] = [a.id, b.id]; break;
        case 'name': [propertyA, propertyB] = [a.name, b.name]; break;
        case 'tenantName': [propertyA, propertyB] = [a.tenantName, b.tenantName]; break;
        case 'unitName': [propertyA, propertyB] = [a.unitName, b.unitName]; break;
        case 'resourceName': [propertyA, propertyB] = [a.resourceName, b.resourceName]; break;
      }

      const valueA = isNaN(+propertyA) ? (propertyA as string)?.toLowerCase() : +propertyA;
      const valueB = isNaN(+propertyB) ? (propertyB as string)?.toLowerCase() : +propertyB;

      return (valueA < valueB ? -1 : 1) * (this._sort.direction === 'asc' ? 1 : -1);
    });
  }
}
