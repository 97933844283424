<div class="container">
  <h3 mat-dialog-title>Add Client Application</h3>

  <form (ngSubmit)="submit" #formControl="ngForm">

    <div class="row">
      <div class="col-md-12">
      <mat-form-field class="example-full-width">
          <input type="text" placeholder="Enterprise" matInput [formControl]="tenantControl" [matAutocomplete]="auto" required>
          <mat-autocomplete (optionSelected)="onTenantChanged($event)" #auto="matAutocomplete" [displayWith]="displayTenantName">
            <mat-option *ngFor="let t of selectedTenants | async" [value]="t">{{ t.name }}</mat-option>
          </mat-autocomplete>
          <mat-error *ngIf="tenantControl.invalid"> Select Enterprise </mat-error>
      </mat-form-field>
    </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <mat-form-field color="accent">
          <mat-select placeholder="Pharmacy" [(value)]="newApplication.unitId" [formControl]="pharmacyControl" required>
            <mat-option *ngFor="let u of units | async" [value]="u.id"> {{u.name}} </mat-option>
          </mat-select>
          <mat-error *ngIf="pharmacyControl.invalid">Select Pharmacy</mat-error>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
        <div class="col-md-12">
          <mat-form-field color="accent">
            <mat-select placeholder="Resource" [(value)]="newApplication.resourceName" [formControl]="resourceControl" required>
              <mat-option *ngFor="let r of data.resourceList" [value]="r">{{ r }}</mat-option>
            </mat-select>
            <mat-error *ngIf="resourceControl.invalid"> Select Resource </mat-error>
          </mat-form-field>
        </div>
      </div>

    <div mat-dialog-actions>
      <button mat-raised-button class="btn btn-danger pull-right" [type]="submit" [disabled]="!tenantControl.valid || !pharmacyControl.valid || !resourceControl.valid"
        [mat-dialog-close]="1" (click)="confirmAdd()">Save</button>
      <button mat-button (click)="onNoClick()" tabindex="-1">Cancel</button>
    </div>
  </form>
</div>
