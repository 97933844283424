import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TenantsListComponent } from './tenants/tenants-list.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { TenantComponent } from './tenants/tenant.component';
import { ClientApplicationListComponent } from './client-applications/client-application-list.component';
import { UserListComponent } from './users/user-list.component';
import { UserEventsListComponent } from './userevents/userEvents-list.component';

import { AuthGuard } from './services/authguard.service';

const routes: Routes = [

  { path: 'tenant/:tenantId', component: TenantComponent, canActivate: [AuthGuard]  },
  { path: 'tenants', component: TenantsListComponent, canActivate: [AuthGuard] },
  { path: 'userevents/:userId', component: UserEventsListComponent, canActivate: [AuthGuard] },
  { path: 'users', component: UserListComponent, canActivate: [AuthGuard] },
  { path: 'applications', component: ClientApplicationListComponent, canActivate: [AuthGuard] },
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: '', component: DashboardComponent, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: true
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
