<div class="container">
  <h3 mat-dialog-title>Edit Pharmacy</h3>

  <form (ngSubmit)="submit" [formGroup]="editUnitForm">

      <div class="row">
          <div class="col-md-12">
              <mat-form-field class="example-full-width">
                  <input matInput #input placeholder="Pharmacy Name" [(ngModel)]="data.name" name="name" formControlName="name" 
                    maxlength="255"
                    required/>
                  <mat-error *ngIf="editUnitForm.value.name">Field is required</mat-error>
              </mat-form-field>
          </div>
  </div>

      <div class="row">
          <div class="col-md-12">
              <mat-form-field class="example-full-width">
                  <input matInput placeholder="Secondary Name" [(ngModel)]="data.secondaryName" name="secondaryName" formControlName="secondaryName" 
                    maxlength="255"/>
              </mat-form-field>
          </div>
  </div>

      <div class="row">
          <div class="col-md-12">
              <mat-form-field class="example-full-width">
                  <input matInput placeholder="Redsail Access Key" [(ngModel)]="data.rak" name="rak"  formControlName="rak" 
                    maxlength="255"
                    required/>
              </mat-form-field>
          </div>
  </div>

      <div class="row">
          <div class="col-md-12">
              <mat-form-field class="example-full-width">
                  <input matInput placeholder="Legacy CorpAccount Id or Empty" [(ngModel)]="data.externalId" name="externalId" formControlName="externalId" 
                    maxlength="255"/>
              </mat-form-field>
          </div>
  </div>

      <div class="row">
          <div class="col-md-12">
              <mat-form-field class="example-full-width">
                  <input matInput placeholder="Comma separated Ncpdp Ids" [(ngModel)]="ncpdpIdsRow" name="ncpdpIds" formControlName="ncpdpIds">
              </mat-form-field>
          </div>
  </div>

      <div class="row">
          <div class="col-md-12">
              <mat-form-field class="example-full-width">
                  <input matInput placeholder="Comma separated NPIs" [(ngModel)]="npisRow" name="npis" formControlName="npis">
              </mat-form-field>
          </div>
  </div>

      <div class="row">
          <div class="col-md-12">
              <mat-form-field class="example-full-width">
                  <input matInput placeholder="DEA" [(ngModel)]="data.dea" name="dea" formControlName="dea"
                    maxlength="255"/>
              </mat-form-field>
          </div>
  </div>

      <div mat-dialog-actions>
    <button mat-raised-button class="btn btn-danger pull-right" [type]="submit" [disabled]="!editUnitForm.valid"
      [mat-dialog-close]="1" (click)="confirmAdd()">Save</button>
          <button mat-button (click)="onNoClick()" tabindex="-1">Cancel</button>
      </div>
  </form>
</div>
