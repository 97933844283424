import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Component, Inject, OnDestroy } from '@angular/core';
import { ClientApplicationService } from '../services/client-application.service';
import { FormControl, Validators } from '@angular/forms';
import { ClientInfo } from '../models/clientInfo';
import { ToasterService } from '../services/toaster.service';
import { RegenerateClientSecretComponent } from './regenerate-client-secret.dialog.component';
import { ClientApp } from '../models/clientApp';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-client-secret.dialog',
  templateUrl: './client-secret.dialog.component.html',
  styleUrls: ['./client-secret.dialog.component.css']
})

export class ClientSecretDialogComponent implements OnDestroy {

  info: ClientInfo;
  clipboard: String;

  destroy$ = new Subject<void>();

  constructor(public dialogRef: MatDialogRef<ClientSecretDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: ClientApp, public dialog: MatDialog,
              public appService: ClientApplicationService, private toaster: ToasterService) {

  }

  formControl = new FormControl('', [
    Validators.required
    // Validators.email,
  ]);

  ngOnInit() {
    this.appService.getClientInfo(this.data.id).pipe(takeUntil(this.destroy$))
      .subscribe(ca => {
        this.info = ca;
        this.clipboard = 'Id: ' + ca.clientId + '\n' + 'Secret: ' + ca.clientSecret;
      });
  }

  getErrorMessage() {
    return this.formControl.hasError('required') ? 'Required field' :
      this.formControl.hasError('email') ? 'Not a valid email' :
        '';
  }

  onNoClick(): void {
    this.dialogRef.close();
  }


  public notify(payload: string) {
    const message = 'Secret for client application "' + this.info.clientId + '"  has been copied to clipboard';
    this.toaster.info(message, 'Copied');
  }
  
  regenerateSecret(){
    const clientApp = this.data;
    const dialogRef = this.dialog.open(RegenerateClientSecretComponent, {
      data: clientApp 
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$), switchMap(() => 
      this.appService.getClientInfo(this.data.id))).subscribe(app => {
        this.info = app;
      });
  }

  ngOnDestroy(): void{
    this.destroy$.next();
    this.destroy$.complete();
  }
}
