import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class LoggingService {

    log(message: string){
        console.log(message);
    }

    constructor() {

        console.log('Config Service Here');
    }
}
