<div class="main-content">
  <div class="container-fluid">
    <div class="card">
      <div class="card-header card-header-info">
          <h4 class="card-title">Users List
          <button mat-icon-button (click)="refresh()">
            <mat-icon>refresh</mat-icon>
          </button>
        </h4>
      </div>

      <div class="card-body">
        <div>
      <mat-form-field floatPlaceholder="never" color="accent">
        <input matInput #filter placeholder="Filter users">
      </mat-form-field>
    </div>

        <mat-table #table [dataSource]="dataSource" matSort class="table">
          <!--- Note that these columns can be defined in any order. The actual rendered columns are set as a property on the row definition" -->

      <!-- ID Column -->
      <ng-container matColumnDef="id">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Id</mat-header-cell>
        <mat-cell *matCellDef="let row" >{{row.id}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="userName">
        <mat-header-cell *matHeaderCellDef mat-sort-header>UserName</mat-header-cell>
        <mat-cell *matCellDef="let row"> {{row.userName}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="lastName">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Last Name</mat-header-cell>
        <mat-cell *matCellDef="let row"> {{row.lastName}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="firstName">
        <mat-header-cell *matHeaderCellDef mat-sort-header>First Name</mat-header-cell>
        <mat-cell *matCellDef="let row"> {{row.firstName}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="emailAddress">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Email</mat-header-cell>
        <mat-cell *matCellDef="let row"> {{row.emailAddress}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="tenantName">
        <mat-header-cell *matHeaderCellDef>Enterprise : Roles</mat-header-cell>
        <mat-cell *matCellDef="let row"> {{getTenantDetails(row)}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="isActive">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Active</mat-header-cell>
        <mat-cell *matCellDef="let row">
            <mat-checkbox [checked]="row.isActive" [disabled]="true"></mat-checkbox>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="isTerminated">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Terminated</mat-header-cell>
        <mat-cell *matCellDef="let row">
            <mat-checkbox [checked]="row.isTerminated" [disabled]="true"></mat-checkbox>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="status">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Status</mat-header-cell>
        <mat-cell *matCellDef="let row">
            {{row.isTerminated ? 'Terminated' : row.isActive ? 'Active' : 'Inactive'}}
        </mat-cell>
      </ng-container>

      <!-- actions -->
      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef>
          <button mat-icon-button color="primary" (click)="addNew()">
            <mat-icon aria-label="add">add</mat-icon>
          </button>
        </mat-header-cell>

        <mat-cell *matCellDef="let row; let i=index;">
          <button mat-icon-button color="accent" [disabled]="row.isADUser" (click)="startEdit(row)" *ngIf="showEdit(row)">
            <mat-icon aria-label="Edit">edit</mat-icon>
          </button>

          <button mat-icon-button color="accent" [disabled]="row.isADUser" (click)="reassignUser(row)" *ngIf="!showDelete(row)">
            <mat-icon aria-label="Edit">edit</mat-icon>
          </button>

          <button mat-icon-button color="accent" [routerLink]="['/userevents', row.id]">
            <mat-icon aria-label="History">list</mat-icon>
          </button>

          <button mat-icon-button color="accent" [disabled]="row.isADUser" (click)="delete(row)" *ngIf="showDelete(row)" >
            <mat-icon aria-label="Delete">delete</mat-icon>
          </button>


        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>


    <div class="no-results" [style.display]="dataSource.renderedData.length == 0 ? '' : 'none'">
      No results
    </div>

    <mat-paginator #paginator
                   [length]="dataSource.filteredData.length"
                   [pageIndex]="0"
                   [pageSize]="10"
                   [pageSizeOptions]="[5, 10, 25, 100]">
    </mat-paginator>
      </div>

    </div>
  </div>
</div>
