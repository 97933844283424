import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeValue } from '@angular/platform-browser';

@Pipe({
  name: 'sanitize',
})
export class SanitizePipe implements PipeTransform {
  constructor(private readonly domSanitizer: DomSanitizer) {}

  public transform(value: SafeValue | string | null, context = SecurityContext.HTML): SafeValue {
    return this.domSanitizer.sanitize(context, value);
  }
}
