import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, fromEvent, merge, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserEvent } from '../models/userEvent';

import { UserService } from '../services/user.service';
import { User } from '../models/user';

import { ConfigService } from '../utils/config.service';
import { PathService } from '../utils/path.service';
import { UserEventService } from '../services/user-event.service';
import { ToasterService } from '../services/toaster.service';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-user-events',
    templateUrl: './userEvents-list.component.html',
    styleUrls: ['./userEvents-list.component.scss']
})
export class UserEventsListComponent implements OnInit {

    displayedColumns = ['eventTime', 'type', 'event', 'actions'];
    exampleDatabase: UserEventService | null;
    dataSource: ExampleDataSource | null;
    index: number;
    id: string;
    user: User;

    clipboard: String;

    constructor(public httpClient: HttpClient,
                public dialog: MatDialog,
                public dataService: UserEventService,
                private userService: UserService,
                private toasterService: ToasterService,
                private configService: ConfigService,
                private path: PathService,
                private _route: ActivatedRoute,
                private toaster: ToasterService
    ) { }

    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;
    @ViewChild('filter', { static: true }) filter: ElementRef;

    ngOnInit() {
        this.id = this._route.snapshot.params.userId;

        this.userService.getuser(this.id).subscribe(data => {
            this.user = data;
        });

        this.loadData();
    }

    refresh() {
        this.loadData();
    }

    public getCopyData(event: UserEvent): string {
        const payload = 'Id:' + event.id +
                    '\nTime:' + event.eventTime +
                    '\nType:' + event.type +
                    '\nDetails:' + event.eventJson;
        return payload;

    }

    public notify(payload: string) {
        const message = payload + ' copied to clipboard';
        this.toaster.info(message, 'Copied');
      }


    details(event: UserEvent) {
        // const dialogRef = this.dialog.open(DeleteUserDialogComponent, {
        //   data: {user: user }
        // });

        // dialogRef.afterClosed().subscribe(result => {
        //   if (result === 1) {
        //     user.isTerminated = true;
        //     const foundIndex = this.exampleDatabase.dataChange.value.findIndex(x => x.id === this.id);
        //     this.exampleDatabase.dataChange.value[foundIndex] = this.dataService.getDialogData();
        //   }
        //   // And lastly refresh table
        //   this.refreshTable();
        // });
    }

    private refreshTable() {
        // Refreshing table using paginator
        // Thanks yeager-j for tips
        // https://github.com/marinantonio/angular-mat-table-crud/issues/12
        this.paginator._changePageSize(this.paginator.pageSize);
    }

    public loadData() {



        this.exampleDatabase = new UserEventService(this.httpClient, this.configService, this.toasterService, this.path);

        this.dataSource = new ExampleDataSource(this.id, this.dataService, this.paginator, this.sort);
        fromEvent(this.filter.nativeElement, 'keyup')
            // .debounceTime(150)
            // .distinctUntilChanged()
            .subscribe(() => {
                if (!this.dataSource) {
                    return;
                }
                this.dataSource.filter = this.filter.nativeElement.value;
            });
    }
}

export class ExampleDataSource extends DataSource<UserEvent> {
    _filterChange = new BehaviorSubject('');

    get filter(): string {
        return this._filterChange.value;
    }

    set filter(filter: string) {
        this._filterChange.next(filter);
    }

    filteredData: UserEvent[] = [];
    renderedData: UserEvent[] = [];

    constructor(public _id: string,
                public _exampleDatabase: UserEventService,
                public _paginator: MatPaginator,
                public _sort: MatSort) {
        super();
        // Reset to the first page when the user changes the filter.
        this._filterChange.subscribe(() => this._paginator.pageIndex = 0);
    }

    /** Connect function called by the table to retrieve one stream containing the data to render. */
    connect(): Observable<UserEvent[]> {
        // Listen for any changes in the base data, sorting, filtering, or pagination
        const displayDataChanges = [
            this._exampleDatabase.dataChange,
            this._sort.sortChange,
            this._filterChange,
            this._paginator.page
        ];

        this._exampleDatabase.getAll(this._id);


        return merge(...displayDataChanges).pipe(map(() => {
            // Filter data
            this.filteredData = this._exampleDatabase.data.slice().filter((event: UserEvent) => {
                const searchStr = (event.id + event.eventTime + event.eventJson).toLowerCase();
                return searchStr.indexOf(this.filter.toLowerCase()) !== -1;
            });

            // Sort filtered data
            const sortedData = this.sortData(this.filteredData.slice());

            // Grab the page's slice of the filtered sorted data.
            const startIndex = this._paginator.pageIndex * this._paginator.pageSize;
            this.renderedData = sortedData.splice(startIndex, this._paginator.pageSize);
            return this.renderedData;
        }
        ));
    }

    disconnect() { }


    /** Returns a sorted copy of the database data. */
    sortData(data: UserEvent[]): UserEvent[] {
        if (!this._sort.active || this._sort.direction === '') {
            return data;
        }

        return data.sort((a, b) => {
            let propertyA: number | string = '';
            let propertyB: number | string = '';

            switch (this._sort.active) {
                case 'id': [propertyA, propertyB] = [a.id, b.id]; break;
                case 'eventTime': [propertyA, propertyB] = [new Date(a.eventTime).getTime(), new Date(b.eventTime).getTime()]; break;
                case 'event': [propertyA, propertyB] = [a.eventJson, b.eventJson]; break;
                case 'type': [propertyA, propertyB] = [a.type, b.type]; break;
            }

            const valueA = isNaN(+propertyA) ? propertyA : +propertyA;
            const valueB = isNaN(+propertyB) ? propertyB : +propertyB;

            return (valueA < valueB ? -1 : 1) * (this._sort.direction === 'asc' ? 1 : -1);
        });
    }
}
