import { Component } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'mako-client';
  // isLoggedIn = false;

  constructor(
    private keycloakService: KeycloakService) {
  }

  login() {
    this.keycloakService.login();
  }

  logout() {
    this.keycloakService.logout();
  }

  isLoggedIn(){
    return this.keycloakService.isLoggedIn;
  }
}
