import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {TenantService } from '../services/tenant.service';
import {HttpClient} from '@angular/common/http';
import {MatDialog} from '@angular/material/dialog';
import {Tenant} from '../models/tenant';
import { ActivatedRoute } from '@angular/router';
import { Unit } from '../models/unit';
import { MatTableDataSource } from '@angular/material/table';
import { AddUnitDialogComponent } from './addUnit.dialog.component';
import { EditUnitDialogComponent } from './editUnit.dialog.component';
import { ToasterService } from '../services/toaster.service';
import { EditTenantDialogComponent } from './editTenant.dialog.component';

@Component({
  selector: 'app-tenant',
  templateUrl: './tenant.component.html',
  styleUrls: ['./tenant.component.scss']
})
export class TenantComponent implements OnInit {

  displayedColumns = ['id', 'name', 'secondaryName', 'rak', 'externalId', 'ncpdpId', 'npis', 'dea','actions'];
  dataSource = new MatTableDataSource();
  units: Unit[];
  tenant: Tenant;

  constructor(public httpClient: HttpClient,
              public dialog: MatDialog,
              public dataService: TenantService,
              private _route:ActivatedRoute) {}

  ngOnInit() {
    var tenantId = this._route.snapshot.params.tenantId;
    this.dataService.getTenant(tenantId).subscribe(tenant => {
        this.tenant = tenant;
        this.units = tenant.units
        this.dataSource.data = this.units;
    });
  }

  refresh(){
    this.dataService.getTenant(this.tenant.id).subscribe(tenant => {
      this.units = tenant.units
      this.dataSource.data = this.units;
    });
  }

  addUnit() {
    var unit = new Unit();
    unit.tenantId = this.tenant.id;

    const dialogRef = this.dialog.open(AddUnitDialogComponent, {
      data: unit
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 1) {
        // After dialog is closed we're doing frontend updates
        // For add we're just pushing a new row inside DataService
        this.units.push(this.dataService.getDialogData());

        // Hack:
        this.refresh();
      }
    });
  }

  startTenantEdit(tenant: Tenant)
  {
    const dialogRef = this.dialog.open(EditTenantDialogComponent, {
      data: tenant
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 1) {
        // Hack:
        this.refresh();
      }
    });
  }

  startEdit(unit: Unit)
  {
    unit.tenantId = this.tenant.id;

    const dialogRef = this.dialog.open(EditUnitDialogComponent, {
      data: unit
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 1) {
        // After dialog is closed we're doing frontend updates
        // For add we're just pushing a new row inside DataService
        this.units.push(this.dataService.getDialogData());

        // Hack:
        this.refresh();
      }
    });
  }
}
