<div class="main-content">
  <div class="container-fluid">
    <div class="card">
      <div class="card-header card-header-warning">
          <h4 class="card-title">Client Applications List
          <button mat-icon-button (click)="refresh()">
            <mat-icon>refresh</mat-icon>
          </button>
        </h4>
      </div>
  
      <div class="card-body">
        <div>
      <mat-form-field floatPlaceholder="never" color="accent">
        <input matInput #filter placeholder="Filter applications">
      </mat-form-field>
    </div>
  
        <mat-table #table [dataSource]="dataSource" matSort class="table">
          <!--- Note that these columns can be defined in any order. The actual rendered columns are set as a property on the row definition" -->
  
      <!-- ID Column -->
      <ng-container matColumnDef="id">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Id</mat-header-cell>
        <mat-cell *matCellDef="let row" >{{row.id}}</mat-cell>
      </ng-container>
  
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
        <mat-cell *matCellDef="let row"> {{row.name}}</mat-cell>
      </ng-container>
  
      <ng-container matColumnDef="tenantId">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Enterprise Id</mat-header-cell>
        <mat-cell *matCellDef="let row"> {{row.tenantId}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="tenantName">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Enterprise</mat-header-cell>
        <mat-cell *matCellDef="let row"> {{row.tenantName}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="unitId">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Pharmacy Id</mat-header-cell>
        <mat-cell *matCellDef="let row"> {{row.unitId}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="unitName">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Pharmacy</mat-header-cell>
        <mat-cell *matCellDef="let row"> {{row.unitName}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="resourceName">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Resource</mat-header-cell>
        <mat-cell *matCellDef="let row"> {{row.resourceName}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="isActive">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Is Active</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.isActive}}
            <mat-checkbox [checked]="row.isActive"></mat-checkbox>

        </mat-cell>
      </ng-container>
  
      <!-- actions -->
      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef>
          <button mat-icon-button color="primary" (click)="addNew()">
            <mat-icon aria-label="Example icon-button with a heart icon">add</mat-icon>
          </button>
        </mat-header-cell>
  
        <mat-cell *matCellDef="let row; let i=index;">
          <button mat-icon-button color="accent" (click)="getInfo(row)">
            <mat-icon aria-label="Info">info</mat-icon>
          </button>

          <button mat-icon-button color="accent" (click)="delete(row)" >
            <mat-icon aria-label="Delete">delete</mat-icon>
          </button>
        </mat-cell>
      </ng-container>
  
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>
  
  
    <div class="no-results" [style.display]="dataSource.renderedData.length == 0 ? '' : 'none'">
      No results
    </div>
  
    <mat-paginator #paginator
                   [length]="dataSource.filteredData.length"
                   [pageIndex]="0"
                   [pageSize]="10"
                   [pageSizeOptions]="[5, 10, 25, 100]">
    </mat-paginator>
      </div>
 
    </div>
  </div>
</div>