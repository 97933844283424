import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, Inject } from '@angular/core';
import { ClientApplicationService } from '../services/client-application.service';
import { ClientApp } from '../models/clientApp';

@Component({
  selector: 'app-delete-client.dialog',
  templateUrl: './delete-client.dialog.component.html',
  styleUrls: ['./delete-client.dialog.component.css']
})

export class DeleteClientComponent {

  constructor(public dialogRef: MatDialogRef<DeleteClientComponent>,
              @Inject(MAT_DIALOG_DATA) public data: ClientApp,
              public appService: ClientApplicationService) { }

  submit() {
    // emppty stuff
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  public confirm(): void {
    this.appService.deleteClient(this.data);
  }
}
