<div class="container">
    <h3 mat-dialog-title>Delete Client Application</h3>

    <form #formControl="ngForm">

        <div class="row">
            <div class="col-md-12">
                Do you want to permanently delete client <strong>{{data.name}}</strong>? Client will no longer function.
            </div>
        </div>

        <div mat-dialog-actions>
            <button mat-raised-button class="btn btn-danger pull-right"
                [mat-dialog-close]="1" (click)="confirm()">Confirm</button>
            <button mat-button (click)="onNoClick()" [type]="submit" tabindex="-1">Cancel</button>
        </div>
    </form>
</div>
