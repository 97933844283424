<div class="main-content">
    <div class="container-fluid">

        <div class="row">
            <div class="col-md-3">

                <div class="card card-profile">
                    <!-- <div class="card-avatar">
                        <a href="javascript:void(0)">
                            <img class="img" src="./assets/img/faces/marc.jpg" />
                        </a>
                    </div> -->
                    <div class="card-body">
                        <!-- <h6 class="card-category text-gray">TENANT</h6> -->
                        <h4 class="card-title">
                            {{tenant?.name}}
                            <button mat-icon-button color="accent" (click)="startTenantEdit(tenant)">
                                <mat-icon aria-label="Edit">edit</mat-icon>
                            </button>
                        </h4>
                        <p class="card-description">
                            RAK: {{tenant?.rak}}
                        </p>
                        <p class="card-description" >
                            External Id: {{tenant?.externalId}}
                        </p>
                        <p class="card-description">
                            Id: {{tenant?.id}}
                        </p>
                        <p class="card-description">
                            Allowed IP Addresses: {{tenant?.allowedIpAddresses}}
                        </p>
                        <p class="card-description">
                            AD Enabled: {{tenant?.isADEnabled}}
                        </p>
                        <p *ngIf="tenant?.isADEnabled" class="card-description">
                            Cloud Tenant Id: {{tenant?.cloudId}}
                        </p>
                    </div>
                </div>

            </div>
            <div class="col-md-9">
                <div class="card">
                    <div class="card-header card-header-danger">
                        <h4 class="card-title">Pharmacies
                            <button mat-icon-button (click)="refresh()">
                                <mat-icon>refresh</mat-icon>
                            </button>
                        </h4>
                    </div>

                    <div class="card-body">
                        <mat-table #table [dataSource]="dataSource" matSort class="mat-cell">
                            <!-- ID Column -->
                            <ng-container matColumnDef="id">
                                <mat-header-cell *matHeaderCellDef mat-sort-header>Id</mat-header-cell>
                                <mat-cell *matCellDef="let row">{{row.id}}</mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="name">
                                <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
                                <mat-cell *matCellDef="let row"> {{row.name}}</mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="secondaryName">
                                <mat-header-cell *matHeaderCellDef mat-sort-header>Secondary Name</mat-header-cell>
                                <mat-cell *matCellDef="let row"> {{row.secondaryName}}</mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="rak">
                                <mat-header-cell *matHeaderCellDef mat-sort-header>Redsail Access Key</mat-header-cell>
                                <mat-cell *matCellDef="let row"> {{row.rak}}</mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="externalId">
                                <mat-header-cell *matHeaderCellDef mat-sort-header>External Id</mat-header-cell>
                                <mat-cell *matCellDef="let row"> {{row.externalId}}</mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="ncpdpId">
                                <mat-header-cell *matHeaderCellDef mat-sort-header>Ncpdp Ids</mat-header-cell>
                                <mat-cell *matCellDef="let row"> {{row.ncpdpIds}}</mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="npis">
                                <mat-header-cell *matHeaderCellDef mat-sort-header>NPIs</mat-header-cell>
                                <mat-cell *matCellDef="let row"> {{row.npis}}</mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="dea">
                                <mat-header-cell *matHeaderCellDef mat-sort-header>DEA</mat-header-cell>
                                <mat-cell *matCellDef="let row"> {{row.dea}}</mat-cell>
                            </ng-container>
                            <!-- actions -->
                            <ng-container matColumnDef="actions">
                                <mat-header-cell *matHeaderCellDef>
                                    <button mat-icon-button color="primary" (click)="addUnit()">
                                        <mat-icon aria-label="Example icon-button with a heart icon">add</mat-icon>
                                    </button>
                                </mat-header-cell>

                                <mat-cell *matCellDef="let row; let i=index;">
                                    <button mat-icon-button color="accent" (click)="startEdit(row)">
                                      <mat-icon aria-label="Edit">edit</mat-icon>
                                    </button>

                                    <!-- <button mat-icon-button color="accent" (click)="deleteItem(i, row.id, row.name, row.externalId)">
            <mat-icon aria-label="Delete">delete</mat-icon>
          </button> -->
                                </mat-cell>
                            </ng-container>

                            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                        </mat-table>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
