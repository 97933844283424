import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, Inject } from '@angular/core';
import { UserService } from '../services/user.service';
import { FormControl, Validators } from '@angular/forms';
import { UserTenant } from '../models/userTenant';

@Component({
  selector: 'app-delete-user.dialog',
  templateUrl: './deleteUserTenant.dialog.component.html',
  styleUrls: ['./deleteUserTenant.dialog.component.css']
})

export class DeleteUserTenantDialogComponent {

  constructor(public dialogRef: MatDialogRef<DeleteUserTenantDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public userService: UserService) { }

  formControl = new FormControl('', [
    Validators.required
    // Validators.email,
  ]);

  getErrorMessage() {
    return this.formControl.hasError('required') ? 'Required field' :
      this.formControl.hasError('email') ? 'Not a valid email' :
        '';
  }

  submit() {
    // emppty stuff
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  public confirm(): void {
    var userTenants = this.data.user.userTenants;
    var tenant = this.data.tenant;

    userTenants.forEach((element,index)=>{
      if(element.tenantName == tenant.tenantName) delete userTenants[index];
    });

    this.data.user.userTenants = userTenants.filter((x): x is UserTenant => x !== null);
  }


}
