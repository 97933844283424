import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Tenant } from '../models/tenant';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ConfigService } from '../utils/config.service';
import { Unit } from '../models/unit';
import { ToasterService } from './toaster.service';
import { PathService } from '../utils/path.service';



@Injectable()
export class TenantService {

  // Define the routes we are going to interact with
  private tenantsUrl = this.path.Combine(this.configService.apiRootUrl, 'api/v0/tenants/');
  private bulkLoadUrl = this.path.Combine(this.configService.apiRootUrl, 'api/v0/bulkconfiguration/tenants');

  dataChange: BehaviorSubject<Tenant[]> = new BehaviorSubject<Tenant[]>([]);

  // Temporarily stores data from dialogs
  dialogData: any;

  constructor(
    private httpClient: HttpClient,
    private configService: ConfigService,
    private toasterService: ToasterService,
    private path: PathService) { }

  get data(): Tenant[] {
    return this.dataChange.value;
  }

  getDialogData() {
    return this.dialogData;
  }

  updateTenant(tenant: Tenant): void {
    this.dialogData = tenant;
  }


  // Read Methods
  getAll(): void {
    var url = this.tenantsUrl;
    this.httpClient.get<Tenant[]>(url).subscribe(data => {
      this.dataChange.next(data);
    },
      (error: HttpErrorResponse) => {
        console.log(error.name + ' ' + error.message);
      });
  }

  getTenants(): Observable<Tenant[]> {
    var url = this.tenantsUrl;
    return this.httpClient.get<Tenant[]>(url);
  }

  getTenant(id: String): Observable<Tenant> {
    var url = this.tenantsUrl + id;
    return this.httpClient.get<Tenant>(url);
  }

  // Create Methods
  addTenant(tenant: Tenant): void {
    var url = this.tenantsUrl;
    this.httpClient.post(url, tenant).subscribe(data => {
      this.dialogData = tenant;
      this.toasterService.success("Enterprise " + tenant.name + " Added");
    },
      (err: HttpErrorResponse) => {
        this.toasterService.errorResponse(err, "Unable to add Enterprise");
      });
  }

  importTenants(file: any): void {
    var url = this.bulkLoadUrl;
    const formData = new FormData();
    formData.append("file", file);
    this.httpClient.post<any>(url, formData).subscribe(data => {
      this.toasterService.success(`Import result: Tenants added: ${data.tenantsAdded}/${data.totalTenatsProcessed}, Units added: ${data.unitsAdded}/${data.totalUnitsProcessed}. See logs for details.`);
    },
      (err: HttpErrorResponse) => {
        this.toasterService.errorResponse(err, "Failed to import Enterprises");
      });
  }

  addUnit(unit: Unit) {
    var url = this.path.Combine(this.tenantsUrl, unit.tenantId + '/units');
    this.httpClient.post(url, unit).subscribe(data => {
      this.dialogData = unit;

      this.toasterService.success("Pharmacy " + unit.name + " Added");
    },
      (err: HttpErrorResponse) => {
        this.toasterService.errorResponse(err, "Unable to add Pharmacy" + unit.name);
      });
  }

  editUnit(unit: Unit) {
    var url = this.path.Combine(this.tenantsUrl, unit.tenantId + '/units/' + unit.id);
    this.httpClient.put(url, unit).subscribe(data => {
      this.dialogData = unit;

      this.toasterService.success("Pharmacy " + unit.name + " Updated.");
    },
      (err: HttpErrorResponse) => {
        this.toasterService.errorResponse(err,  "Unable to update Pharmacy " + unit.name);
      });

  }

  editTenant(tenant: Tenant) {
    var url = this.path.Combine(this.tenantsUrl, tenant.id);
    this.httpClient.put(url, tenant).subscribe(data => {
      this.dialogData = tenant;

      this.toasterService.success("Enterprise " + tenant.name + " Updated.");
    },
      (err: HttpErrorResponse) => {
        this.toasterService.errorResponse(err, "Unable to update Enterprise");
      });

  }

  // Update Methods
  updateItem(tenant: Tenant): void {
    var url = this.path.Combine(this.tenantsUrl, tenant.id);
    this.httpClient.put(url, tenant).subscribe(data => {
      this.dialogData = tenant;
      this.toasterService.success("Enterprise " + tenant.name + " Updated");
    },
      (err: HttpErrorResponse) => {
        this.toasterService.error(err.name, err.message, "Unable to update Enterprise " + tenant.name);
      }
    );
  }
}
