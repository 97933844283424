<div class="main-content">
    <div class="container-fluid">
        <div class="card">
            <div class="card-header card-header-success">
                <h4 class="card-title">User Events for {{ user.lastName }}, {{ user.firstName }}
                    <button mat-icon-button (click)="refresh()">
                        <mat-icon>refresh</mat-icon>
                    </button>
                </h4>
            </div>

            <div class="card-body">
                <div>
                    <mat-form-field floatPlaceholder="never" color="accent">
                        <input matInput #filter placeholder="Filter events">
                    </mat-form-field>
                </div>

                <mat-table #table [dataSource]="dataSource" matSort class="table">
                    <!--- Note that these columns can be defined in any order. The actual rendered columns are set as a property on the row definition" -->

                    <!-- ID Column -->
                    <ng-container matColumnDef="id">
                        <mat-header-cell *matHeaderCellDef mat-sort-header>Id</mat-header-cell>
                        <mat-cell *matCellDef="let row">{{row.id}}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="type">
                        <mat-header-cell *matHeaderCellDef mat-sort-header>Type</mat-header-cell>
                        <mat-cell *matCellDef="let row"> {{row.type}}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="event">
                        <mat-header-cell *matHeaderCellDef mat-sort-header>Details</mat-header-cell>
                        <mat-cell *matCellDef="let row"><pre>{{row.eventJson }}</pre></mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="eventTime">
                        <mat-header-cell *matHeaderCellDef mat-sort-header>Local Time</mat-header-cell>
                        <mat-cell *matCellDef="let row"> {{row.eventTime | date:'medium' }}</mat-cell>
                    </ng-container>

                    <!-- actions -->
                    <ng-container matColumnDef="actions">
                        <mat-header-cell *matHeaderCellDef>
                        </mat-header-cell>

                        <mat-cell *matCellDef="let row; let i=index;">
                            <button mat-icon-button color="accent" [copy-clipboard]="getCopyData(row)"
                                (copied)="notify(row.type)">
                                <mat-icon aria-label="Copy">content_copy</mat-icon>
                            </button>
                        </mat-cell>
                    </ng-container>


                    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                </mat-table>

                <div class="no-results" [style.display]="dataSource.renderedData.length == 0 ? '' : 'none'">
                    No results
                </div>

                <mat-paginator #paginator [length]="dataSource.filteredData.length" [pageIndex]="0" [pageSize]="10"
                    [pageSizeOptions]="[5, 10, 25, 100]">
                </mat-paginator>
            </div>

        </div>
    </div>
</div>
