<div class="container">
  <h3 mat-dialog-title>Edit Enterprise</h3>

  <form (ngSubmit)="submit" [formGroup]="editTenantForm">

    <div class="row">
      <div class="col-md-12">
        <mat-form-field class="example-full-width">
          <input matInput #input placeholder="Enterprise Name" [(ngModel)]="data.name" name="name" formControlName="name" 
            maxlength="255"
            required/>
          <mat-error *ngIf="editTenantForm.value.name">Field is required</mat-error>
          </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <mat-form-field class="example-full-width">
          <input matInput placeholder="Redsail Access Key" [(ngModel)]="data.rak" name="rak" formControlName="rak" 
            maxlength="255"
            required/>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <mat-form-field class="example-full-width">
          <input matInput placeholder="Legacy CorpAccountId or Empty" [(ngModel)]="data.externalId" name="externalId" formControlName="externalId"  
            maxlength="255"/>
        </mat-form-field>
      </div>
    </div> 

    <div class="row">
      <div class="col-md-12">
        <mat-form-field class="example-full-width">
          <input matInput placeholder="Allowed IP Addresses (1.1.1.1-2.2.2.2, 3.3.3.3/3)" [(ngModel)]="ipAddresses" name="ipAddresses" formControlName="allowedIps" >           
        </mat-form-field>
      </div>
    </div>   
    
    <div class="row">
      <div class="col-md-12">
        <mat-slide-toggle color="accent" [(ngModel)]="data.isADEnabled" name="isADEnabled" formControlName="isADEnabled" >
          AD Enabled
        </mat-slide-toggle>
      </div>
    </div>

    <div *ngIf="data.isADEnabled" class="row">
      <div class="col-md-12">
        <mat-form-field class="example-full-width">
          <input matInput placeholder="Cloud Tenant Id" [(ngModel)]="data.cloudId" name="cloudId" [(required)]="data.isADEnabled" formControlName="cloudId" >
        </mat-form-field>
      </div>
    </div> 

    <div mat-dialog-actions>
      <button mat-raised-button class="btn btn-danger pull-right" [type]="submit" [disabled]="!editTenantForm.valid"
        [mat-dialog-close]="1" (click)="confirmEdit()">Save</button>
      <button mat-button (click)="onNoClick()" tabindex="-1">Cancel</button>
    </div>
  </form>
</div>
