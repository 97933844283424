<div class="main-content">
  <div class="container-fluid">
    <div class="card">
      <div class="card-header card-header-success">
          <h4 class="card-title">Enterprise List
          <button mat-icon-button (click)="refresh()">
            <mat-icon>refresh</mat-icon>
          </button>
          <button mat-icon-button (click)="import()">
            <mat-icon>cloud_upload</mat-icon>
          </button>
        </h4>
      </div>

      <div class="card-body">
        <div>
          <mat-form-field floatPlaceholder="never" color="accent">
            <input matInput #filter placeholder="Filter enterprises">
          </mat-form-field>
        </div>

        <mat-table #table [dataSource]="dataSource" matSort class="table">
          ng update @angular/cli @angular/core
          <!--- Note that these columns can be defined in any order. The actual rendered columns are set as a property on the row definition" -->

          <!-- ID Column -->
          <ng-container matColumnDef="id">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Id</mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.id}}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
            <mat-cell *matCellDef="let row"> {{row.name}}</mat-cell>
          </ng-container>          

          <ng-container matColumnDef="rak">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Redsail Access Key</mat-header-cell>
            <mat-cell *matCellDef="let row"> {{row.rak}}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="externalId">
            <mat-header-cell *matHeaderCellDef>External Id</mat-header-cell>
            <mat-cell *matCellDef="let row"> {{row.externalId}}</mat-cell>
          </ng-container>                  

          <ng-container matColumnDef="pharmacies">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Pharmacies</mat-header-cell>
            <mat-cell *matCellDef="let row"> {{ row.units.length }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="allowedIps">
            <mat-header-cell *matHeaderCellDef>Allowed IP Addresses</mat-header-cell>
            <mat-cell *matCellDef="let row"> {{row.allowedIpAddresses}}</mat-cell>
          </ng-container> 
          <ng-container matColumnDef="adEnabled">
            <mat-header-cell *matHeaderCellDef mat-sort-header>AD Enabled</mat-header-cell>
            <mat-cell *matCellDef="let row"> {{row.isADEnabled}}</mat-cell>
          </ng-container> 

          <!-- actions -->
          <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef>
              <button mat-icon-button color="primary" (click)="addNew()">
                <mat-icon aria-label="Example icon-button with a heart icon">add</mat-icon>
              </button>
            </mat-header-cell>

            <mat-cell *matCellDef="let row; let i=index;">
              <!-- <button mat-icon-button color="accent" (click)="startEdit(i, row.id, row.name, row.externalId)">
            <mat-icon aria-label="Edit">edit</mat-icon>
          </button>
          -->
              <button mat-icon-button color="accent" [routerLink]="['/tenant', row.id]">
                <mat-icon aria-label="Edit">edit</mat-icon>
              </button>

              <!-- <button mat-icon-button color="accent" (click)="deleteItem(i, row.id, row.name, row.externalId)">
            <mat-icon aria-label="Delete">delete</mat-icon>
          </button> -->
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>


        <div class="no-results" [style.display]="dataSource.renderedData.length == 0 ? '' : 'none'">
          No results
        </div>

        <mat-paginator #paginator [length]="dataSource.filteredData.length" [pageIndex]="0" [pageSize]="10"
          [pageSizeOptions]="[5, 10, 25, 100]">
        </mat-paginator>
      </div>

    </div>
  </div>
</div>
