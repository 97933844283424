import { Component, OnInit } from '@angular/core';
import { Observable, VirtualTimeScheduler } from 'rxjs';
import { Dashboard, ChartItem } from '../models/dashboard';
import { DashboardService } from '../services/dashboard.service';
import * as Chartist from 'chartist';
import { TenantDashboardInfo } from '../models/tenantDashboardInfo';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  data: Dashboard;
  userEventsLabel = 'Last x days';
  userEventsChange = 0;

  tenantUsers: TenantDashboardInfo[];
  tenantUnits: TenantDashboardInfo[];

  constructor(private dashboardService: DashboardService) { }

  ngOnInit(): void {
    this.loadData();
  }

  loadData(): void {
    this.loadUserEvents();

    this.dashboardService.getData().subscribe(d => {
      this.data = d;
    });

    this.loadTenantSummaries();
  }

  loadTenantSummaries(){
    const rows = 4;

    this.dashboardService.getTopTenantsByUser(rows).subscribe(d => {
      this.tenantUsers = d;
    });

    this.dashboardService.getTopTenantsByUnit(rows).subscribe(d => {
      this.tenantUnits = d;
    });
  }


  loadUserEvents() {
    const days = 14;

    this.dashboardService.getEvents(days).subscribe(e => {
      // this.userEvents = e;
      const chartItems = e;

      const dayLabels = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];

      const dataLabels = [];
      const dataSeries = [];
      for (let i = 0; i < chartItems.length; i++) {
        const day = new Date(e[i].date);

        dataLabels.push(dayLabels[day.getDay()]);
        dataSeries.push(e[i].count);
      }

      const data: any = {
        labels: dataLabels,
        series: [
          dataSeries,
          // second series
        ]
      };

      // once in a wile we get to have some fun
      const maxCount = Math.max(...dataSeries);
      const offset = maxCount < 50 ? 5 :
                   maxCount < 100 ? 10 :
                   maxCount < 200 ? 20 :
                   maxCount < 500 ? 50 :
                   100;

      const optionsEventChart: any = {
        lineSmooth: Chartist.Interpolation.cardinal({
          tension: 0
        }),
        low: 0,
        high: maxCount + offset, // recommend you to set the high sa the biggest value + something for a better look
        chartPadding: { top: 0, right: 0, bottom: 0, left: 0 },
      };

      const userEventsChart = new Chartist.Line('#userEventsChart', data, optionsEventChart);
      this.startAnimationForLineChart(userEventsChart);

      // static labels
      this.userEventsLabel = 'Last ' + days + ' days';

      const today = dataSeries[dataSeries.length - 1];
      const yesterday = dataSeries[dataSeries.length - 2];
      if (yesterday == 0 && today > 0){
        this.userEventsChange = 100;
      }
      else if (yesterday == today){
        this.userEventsChange = 0;
      }
      else {
        this.userEventsChange = ((today - yesterday) / yesterday ) * 100;
      }



    });
  }



  startAnimationForLineChart(chart) {
    let seq: any, delays: any, durations: any;
    seq = 0;
    delays = 80;
    durations = 500;

    chart.on('draw', function(data) {
      if (data.type === 'line' || data.type === 'area') {
        data.element.animate({
          d: {
            begin: 600,
            dur: 700,
            from: data.path.clone().scale(1, 0).translate(0, data.chartRect.height()).stringify(),
            to: data.path.clone().stringify(),
            easing: Chartist.Svg.Easing.easeOutQuint
          }
        });
      } else if (data.type === 'point') {
        seq++;
        data.element.animate({
          opacity: {
            begin: seq * delays,
            dur: durations,
            from: 0,
            to: 1,
            easing: 'ease'
          }
        });
      }
    });

    seq = 0;
  }

  startAnimationForBarChart(chart) {
    let seq2: any, delays2: any, durations2: any;

    seq2 = 0;
    delays2 = 80;
    durations2 = 500;
    chart.on('draw', function(data) {
      if (data.type === 'bar') {
        seq2++;
        data.element.animate({
          opacity: {
            begin: seq2 * delays2,
            dur: durations2,
            from: 0,
            to: 1,
            easing: 'ease'
          }
        });
      }
    });

    seq2 = 0;
  }

}

