import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {Component, Inject} from '@angular/core';
import {TenantService} from '../services/tenant.service';
import {FormControl, Validators} from '@angular/forms';
import {Tenant} from '../models/tenant';

@Component({
  selector: 'app-import-tenants.dialog',
  templateUrl: './importTenants.dialog.html',
  styleUrls: ['./importTenants.dialog.css']
})

export class ImportTenantsDialogComponent {
  control: FormControl = new FormControl("", Validators.required);
  fileName = '';
  file = null;

  constructor(public dialogRef: MatDialogRef<ImportTenantsDialogComponent>,
              public tenantService: TenantService) { }

  submit() {
  // emppty stuff
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onFileSelected(event) {
    this.file = event.target.files[0];
    if (this.file) {
        this.fileName = this.file.name;
    } else {
      this.fileName = '';
    }
    this.control.patchValue(this.fileName);
}

  public confirmImport(): void {      
    this.tenantService.importTenants(this.file);
  }
}
