import { LoggingService } from './logging.service';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const LoggingServiceFactory = () => {
    const loggingService = new LoggingService();

    console.log('Arrived in LoggingServiceFactory');

    return loggingService;
};

export const LoggingServiceProvider = {
    provide: LoggingService,
    useFactory: LoggingServiceFactory,
    deps: [],
};
