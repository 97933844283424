import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {TenantService} from '../services/tenant.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Tenant} from '../models/tenant';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-add-tenant.dialog',
  templateUrl: './addTenant.dialog.html',
  styleUrls: ['./addTenant.dialog.css']
})

export class AddTenantDialogComponent implements OnInit, OnDestroy {
  addTenantForm : FormGroup;
  private destory$ = new Subject<void>();

  constructor(public dialogRef: MatDialogRef<AddTenantDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: Tenant,
              public tenantService: TenantService) { }

  ngOnInit(): void {
    this.addTenantForm = new FormGroup({
      name: new FormControl('', [Validators.required]),
      rak: new FormControl('', [Validators.required]),
      externalId: new FormControl(''),
      allowedIps: new FormControl('', [Validators.pattern('^[0-9///./,/-]*$')]),
      isADEnabled: new FormControl(false),
      cloudId: new FormControl(''),
    });

    this.addTenantForm.get('isADEnabled').valueChanges.pipe(takeUntil(this.destory$))
    .subscribe(value => {
      if(value) {
        this.addTenantForm.get('cloudId').setValidators(Validators.required);
        this.addTenantForm.get('cloudId').setErrors({'incorrect': true});
        this.data.cloudId = ''
      } else {
        this.addTenantForm.get('cloudId').clearValidators();
        this.addTenantForm.get('cloudId').setErrors(null);
        this.data.cloudId = ''
      }});
  }

  submit() {
  // emppty stuff
  }

  validateInputIpAddress(): boolean {
    //TODO validate input.
    return true;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  public confirmAdd(): void {   
    this.data.allowedIpAddresses = this.data.ipAddresses?.split(',').filter(n => n) ?? [];     
    this.tenantService.addTenant(this.data);
  }

  ngOnDestroy(): void {
      this.destory$.next();
      this.destory$.complete();
  }
}
