<div class="container">
    <h3 mat-dialog-title>Reassign User</h3>

    <form (ngSubmit)="submit" #formControl="ngForm">
        <div class="row">
            <div class="col-md-12">
                <div class="card card-profile">
                    <div class="card-body">
                        <h4 class="card-title">{{data.user.lastName + ', ' + data.user.firstName }}</h4>
                        <p class="card-description">User Name: {{data.user.userName }}</p>
                        <p class="card-description">Email Address: {{data.user.emailAddress }}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <mat-form-field class="example-full-width">
                    <input type="text" placeholder="Enterprise" matInput [formControl]="tenantControl" [matAutocomplete]="auto" required>
                        <mat-autocomplete (optionSelected)="onTenantChanged($event)" #auto="matAutocomplete" [displayWith]="displayTenantName">
                            <mat-option *ngFor="let t of selectedTenants | async" [value]="t">{{ t.name }}</mat-option>
                        </mat-autocomplete>
                        <mat-error *ngIf="tenantControl.invalid"> Select Enterprise </mat-error>
                </mat-form-field>
            </div>
        </div>

        <div class="row"><div class="col-md-12">
            <mat-list>
              <mat-list-item *ngFor="let role of data.roles | async ; let i = index">
                <mat-checkbox [value]="role.value" (change)="onRoleChange($event)">{{ role.name }}</mat-checkbox>
              </mat-list-item>
            </mat-list>
          </div>
        </div>

        <div mat-dialog-actions>
      <button mat-raised-button class="btn btn-danger pull-right" [type]="submit" [disabled]="!formControl.valid || !tenantControl.valid"
        [mat-dialog-close]="1" (click)="confirmReassign()">Reassign</button>
            <button mat-button (click)="onNoClick()" tabindex="-1">Cancel</button>
        </div>
    </form>
</div>