import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class ConfigService {
    // Keycloak Configuration
    keycloakUrl = 'DEFAULT';
    keycloakRealm = 'DEFAULT';
    keycloakClientId = 'DEFAULT';

    apiRootUrl = 'DEFAULT';
    environment = 'DEFAULT';
    enableDebugMessages = true;

    constructor() {}
}
