<div class="container">
    <h3 mat-dialog-title>Edit User</h3>

  <form (ngSubmit)="submit" [formGroup]="editUserForm">
	<div class="row">
      <div class="col-md-6">
        <mat-form-field class="example-full-width">
          <input matInput #input placeholder="Username" [(ngModel)]="data.user.userName" name="userName" formControlName="userName" required />
          <mat-error *ngIf="editUserForm.value.userName">Field is required</mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-6">
        <mat-form-field class="example-full-width">
          <input matInput #input placeholder="Email Address" [(ngModel)]="data.user.emailAddress" name="emailAddress" formControlName="emailAddress" required email />
          <mat-error *ngIf="editUserForm.value.emailAddress">Invalid email</mat-error>
        </mat-form-field>
      </div>
    </div>

	<div class="row">
    <div class="col-md-6">
      <mat-form-field class="example-full-width">
        <input matInput #input placeholder="First Name" [(ngModel)]="data.user.firstName" name="firstName" formControlName="firstName" required />
        <mat-error *ngIf="editUserForm.value.firstName">Field is required</mat-error>
      </mat-form-field>
    </div>
    <div class="col-md-6">
      <mat-form-field class="example-full-width">
        <input matInput #input placeholder="Last Name" [(ngModel)]="data.user.lastName" name="lastName" formControlName="lastName" required />
        <mat-error *ngIf="editUserForm.value.lastName">Field is required</mat-error>
      </mat-form-field>
    </div>
    <div class="col-md-6">
      <mat-form-field class="example-full-width">
        <input matInput #input placeholder="Phone Number" [(ngModel)]="data.user.phoneNumber" name="phoneNumber" formControlName="phoneNumber" />
        <mat-error *ngIf="editUserForm.value.phoneNumber">Invalid Phone Number</mat-error>
      </mat-form-field>
    </div>
  </div>

  <div>
    <mat-table #table [dataSource]="dataSource" matSort class="table">
    <ng-container matColumnDef="tenantName">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Enterprise Name </mat-header-cell>
      <mat-cell *matCellDef="let row"> {{row.tenantName}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="roles">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Roles</mat-header-cell>
      <mat-cell *matCellDef="let row"> {{row.roles.join()}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef>
        <button mat-icon-button color="primary" (click)="addNew()">
          <mat-icon aria-label="add">add</mat-icon>
        </button>
      </mat-header-cell>

      <mat-cell *matCellDef="let row; let i=index;">
        <button mat-icon-button color="accent" (click)="startTenantEdit(row)" *ngIf="showEdit(row)">
          <mat-icon aria-label="Edit">edit</mat-icon>
        </button>

        <button mat-icon-button color="accent" (click)="deleteTenant(row)" *ngIf="showDelete(row)" >
          <mat-icon aria-label="Delete">delete</mat-icon>
        </button>


      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>
  </div>


<div class="row">

  <mat-slide-toggle name="isActive" color="accent" [(ngModel)]="data.user.isActive">
                Is Active
  </mat-slide-toggle>
</div>

    <div mat-dialog-actions>
      <button mat-button class="btn btn-danger pull-right" [type]="submit" [disabled]="!editUserForm.valid" [mat-dialog-close]="1"
                (click)="confirmEdit()">Save</button>
      <button mat-button (click)="onNoClick()" tabindex="-1">Cancel</button>
    </div>
  </form>
</div>
