<footer class="footer ">
    <div class="container-fluid">
        <nav class="pull-left">
            <ul>
                <!-- <li>
                    <a href="https://links here as appropriate">
                        link text
                    </a>
                </li> -->
            </ul>
        </nav>
        <div class="copyright pull-right">
            <!-- &copy;
            {{test | date: 'yyyy'}}, 
            Us -->
        </div>
    </div>
</footer>
