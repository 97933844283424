<nav class="navbar navbar-expand-lg navbar-transparent  navbar-absolute fixed-top">
    <div class="container-fluid">
        <div class="navbar-wrapper">
          <a class="navbar-brand" href="javascript:void(0)">{{getTitle()}}</a>
        </div>
        <button mat-raised-button class="navbar-toggler" type="button" (click)="sidebarToggle()">
            <span class="sr-only">Toggle navigation</span>
            <span class="navbar-toggler-icon icon-bar"></span>
            <span class="navbar-toggler-icon icon-bar"></span>
            <span class="navbar-toggler-icon icon-bar"></span>
        </button>
        <div class="collapse navbar-collapse justify-content-end" id="navigation">
            <ul class="navbar-nav">
                <li *ngIf="!isLoggedIn"  class="nav-item">
                    <a class="nav-link" (click)="login()">
                        <i class="material-icons">person</i>
                        <p>
                            <span class="d-lg-none d-md-block">Login</span>
                        </p>
                    </a>
                </li>
                <li *ngIf="isLoggedIn"  class="nav-item d-flex align-items-center">
                    <span class="lh-1">{{ userName }}</span>
                    <a class="nav-link" role="button" (click)="logout()">
                        <i class="material-icons">person</i>
                        Logout
                    </a>
                </li>
            </ul>
        </div>
    </div>
</nav>
