import { ConfigService } from './config.service';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const ConfigServiceFactory = () => {
    const configService = new ConfigService();

    // console.log("Arrived in ConfigServiceFactory");

    // Read in environment variables
    const browserWindow = window || {};
    // eslint-disable-next-line dot-notation
    const browserWindowConfig = browserWindow['__config'] || {};

    // migrate all variables into the config
    for (const key in browserWindowConfig) {
        if (Object.prototype.hasOwnProperty.call(browserWindowConfig, key)) {
            // eslint-disable-next-line dot-notation
            configService[key] = window['__config'][key];
        }
    }
    // if (configService.enableDebugMessages) {}

    return configService;
};

export const ConfigServiceProvider = {
    provide: ConfigService,
    useFactory: ConfigServiceFactory,
    deps: [],
};
