import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class PathService {

    Combine(first: string, second: string){

        // clean them up:
        if (!first.endsWith('/')){
            first += '/';
        }

        if (second.startsWith('/')){
            second = second.substring(1);
        }

        return first + second;
    }

    constructor() {}
}
