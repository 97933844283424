import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, Inject } from '@angular/core';
import { ClientApplicationService } from '../services/client-application.service';
import { ClientApp } from '../models/clientApp';

@Component({
  selector: 'app-regenerate-client-secret.dialog',
  templateUrl: './regenerate-client-secret.dialog.component.html',
  styleUrls: ['./regenerate-client-secret.dialog.component.css']
})

export class RegenerateClientSecretComponent {

  constructor(public dialogRef: MatDialogRef<RegenerateClientSecretComponent>,
              @Inject(MAT_DIALOG_DATA) public data: ClientApp,
              public appService: ClientApplicationService) { }

  submit() {
    // emppty stuff
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  public confirm(): void {
    this.appService.regenerateClientSecret(this.data);
  }

}
