<div class="container">
    <h3 mat-dialog-title>Terminate User</h3>

    <form (ngSubmit)="submit" #formControl="ngForm">

        <div class="row">
            <div class="col-md-12">
                Remove this user from Enterprise <strong>{{data.user.tenantName}}</strong>?
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <div class="card card-profile">
                    <div class="card-body">
                        <h4 class="card-title">{{data.user.lastName + ', ' + data.user.firstName }}</h4>
                        <p class="card-description">User Name: {{data.user.userName }}</p>
                        <p class="card-description">Email Address: {{data.user.emailAddress }}</p>
                    </div>
                </div>
            </div>
        </div>

        <div mat-dialog-actions>
            <button mat-raised-button class="btn btn-danger pull-right" [type]="submit" [disabled]="!formControl.valid"
                [mat-dialog-close]="1" (click)="confirm()">Confirm</button>
            <button mat-button (click)="onNoClick()" tabindex="-1">Cancel</button>
        </div>
    </form>
</div>