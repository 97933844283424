<div class="main-content">
    <div class="container-fluid">
        <div class="row">

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="card card-stats">
                    <div class="card-header card-header-success card-header-icon">
                        <div class="card-icon">
                            <i class="material-icons">store</i>
                        </div>
                        <p class="card-category">Total Enterprises</p>
                        <h3 class="card-title">{{data.totalTenants}}</h3>
                    </div>
                    <div class="card-footer">
                        <div class="stats">
                            <i class="material-icons">local_offer</i> Total Enterprises
                        </div>
                    </div>
                </div>
            </div>



            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="card card-stats">
                    <div class="card-header card-header-warning card-header-icon">
                        <div class="card-icon">
                            <i class="material-icons">content_paste</i>
                        </div>
                        <p class="card-category">Client Applications</p>
                        <h3 class="card-title">{{data.clientApplications}}
                        </h3>
                    </div>
                    <div class="card-footer">
                        <div class="stats">
                            <i class="material-icons">local_offer</i> Total Apps
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="card card-stats">
                    <div class="card-header card-header-info card-header-icon">
                        <div class="card-icon">
                            <i class="material-icons">person</i>
                        </div>
                        <p class="card-category">Active Users</p>
                        <h3 class="card-title">{{data.activeUsers}}</h3>
                    </div>
                    <div class="card-footer">
                        <div class="stats">
                            <i class="material-icons">person</i> {{data.totalUsers}} Total Users
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <div class="card card-chart">
                    
                    <div class="card-header card-header-success">
                        <div class="ct-chart" id="userEventsChart"></div>
                    </div>


                    <div class="card-body">
                        <h4 class="card-title">User Events</h4>
                        <p class="card-category">
                            <span *ngIf="userEventsChange > 0 && userEventsChange <= 100">
                                <span class="text-warning">
                                    <i class="material-icons">arrow_upward</i>
                                    {{ userEventsChange | number:'1.0-0' }}%</span> increase in today's events.
                            </span>
                            <span *ngIf="userEventsChange > 100">
                                <span class="text-danger">
                                    <i class="material-icons">arrow_upward</i>
                                    {{ userEventsChange | number:'1.0-0' }}%</span> increase in today's events.
                            </span>
                            <span *ngIf="userEventsChange < 0">
                                <span class="text-success">
                                    <i class="material-icons">arrow_downward</i>
                                    {{ userEventsChange | absoluteValue | number:'1.0-0' }}%</span> decrease in today's events.
                            </span>

                        </p>
                    </div>
                    <div class="card-footer">
                        <div class="stats">
                            <i class="material-icons">access_time</i> {{ userEventsLabel }}.  Indicator of configuration stability.
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">

            <div class="col-md-6">
                <div class="card">
                    <div class="card-header card-header-primary">
                        <h4 class="card-title">Top Enterprises By User</h4>
                        <p class="card-category">Enterprises with most users</p>
                    </div>
                    <div class="card-body table-responsive">
                        <table class="table table-hover">
                            <thead class="text-warning">
                                <th></th>
                                <th>Name</th>
                                <th class="td-total">Users</th>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of tenantUsers; let indexOfelement=index;">
                                    <td>{{ indexOfelement + 1 }}</td>
                                    <td>{{ item.name }}</td>
                                    <td class="td-total">{{ item.count }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div class="col-md-6">
                <div class="card">
                    <div class="card-header card-header-warning">
                        <h4 class="card-title">Top Enterprises By Pharmacy</h4>
                        <p class="card-category">Enterprises with most Pharmacies</p>
                    </div>
                    <div class="card-body table-responsive">
                        <table class="table table-hover">
                            <thead class="text-warning">
                                <th></th>
                                <th>Name</th>
                                <th class="td-total">Pharmacies</th>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of tenantUnits; let indexOfelement=index;" >
                                    <td>{{ indexOfelement + 1 }}</td>
                                    <td>{{ item.name }}</td>
                                    <td class="td-total">{{ item.count }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>