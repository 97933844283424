import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { User } from '../models/user';
import { ToasterService } from './toaster.service';
import { ConfigService } from '../utils/config.service';
import { PathService } from '../utils/path.service';
import { UserEvent } from '../models/userEvent';

@Injectable()
export class UserEventService {

  // Define the routes we are going to interact with
  private usersUrl = this.path.Combine(this.configService.apiRootUrl, 'api/v0/users/');
  private eventsUrl = this.path.Combine(this.configService.apiRootUrl, 'api/v0/userevents/');

  dataChange: BehaviorSubject<UserEvent[]> = new BehaviorSubject<UserEvent[]>([]);

  // Temporarily stores data from dialogs
  dialogData: any;

  constructor(private httpClient: HttpClient,
              private configService: ConfigService,
              private toasterService: ToasterService, private path: PathService) { }

  get data(): UserEvent[] {
    return this.dataChange.value;
  }

  getDialogData() {
    return this.dialogData;
  }

  // Read Methods
  getAll(id: string): void {

    let url = this.eventsUrl + id;
    this.httpClient.get<UserEvent[]>(url).subscribe(data => {
        this.dataChange.next(data);
    },
      (err: HttpErrorResponse) => {
        this.toasterService.error(err.name, err.message, 'Unable to retrieve user events');
      });
  }

  getuser(id: String): Observable<User> {
    let url = this.usersUrl + id;
    return this.httpClient.get<User>(url);
  }
}
