<div class="container">
    <h3 mat-dialog-title>Client Secret
        <button mat-icon-button color="accent" [copy-clipboard]="clipboard" (copied)="notify($event)">
            <mat-icon aria-label="Copy">content_copy</mat-icon>
        </button>
    </h3>

    <form #formControl="ngForm">
        <div class="row">
            <div class="col-md-12">
                <mat-form-field class="example-full-width">
                    <input matInput #input placeholder="Client Id" [(ngModel)]="info.clientId" name="clientId"
                        disabled />
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <mat-form-field class="example-full-width">
                    <input matInput #input placeholder="Client Secret" [(ngModel)]="info.clientSecret"
                        name="clientSecret" disabled />
                </mat-form-field>
            </div>
        </div>
        <div mat-dialog-actions>
            <button mat-raised-button class="btn btn-danger pull-right" (click)="regenerateSecret()">Regenerate Secret</button>
            <button mat-button (click)="onNoClick()" tabindex="-1">Close</button>
        </div>
    </form>
</div>
