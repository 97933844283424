// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  envName: 'local',
  ENV: 'prod',
  // apiRoot: 'http://host.docker.internal:9111/api/v0/',

  // keycloak: {
  //   // Url of the Identity Provider
  //   issuer: 'http://host.docker.internal:8111/auth/',

  //   // Realm
  //   realm: 'dev',

  //   // The SPA's id.
  //   // The SPA is registerd with this id at the auth-serverß
  //   clientId: 'mako-ui',
  // },
};
