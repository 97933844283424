import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {MatDialog} from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import {Component, Inject, OnInit} from '@angular/core';
import {UserService} from '../services/user.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import { UserRole } from '../models/userRole';
import { UserTenant } from '../models/userTenant';
import { EditUserTenantDialogComponent } from './editUserTenant.dialog.component';
import { AddUserTenantDialogComponent } from './addUserTenant.dialog.component';
import { DeleteUserTenantDialogComponent } from './deleteUserTenant.dialog.component';

@Component({
  selector: 'app-edit-user.dialog',
  templateUrl: './editUser.dialog.component.html',
  styleUrls: ['./editUser.dialog.component.css']
})

export class EditUserDialogComponent implements OnInit{

  dataSource : MatTableDataSource<UserTenant>;
  displayedColumns = ['tenantName', 'roles',  'actions'];
  userCredentials: any = {};

  editUserForm : FormGroup;

  constructor(public dialogRef: MatDialogRef<EditUserDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public dialog: MatDialog,
              public userService: UserService) { }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource(this.data.user.userTenants);
    this.userCredentials.userName = this.data.user.userName;
    this.userCredentials.emailAddress = this.data.user.emailAddress;

    this.editUserForm = new FormGroup({
      userName: new FormControl(this.data.user.userName, [Validators.required]),
      emailAddress: new FormControl(this.data.user.emailAddress, [Validators.required]),
      firstName: new FormControl(this.data.user.firstName, [Validators.required]),
      lastName: new FormControl(this.data.user.lastName, [Validators.required]),
      phoneNumber: new FormControl(this.data.user.phoneNumber, [Validators.pattern('^[0-9]{10}?$')])
    });
  }


  submit() {
  // emppty stuff
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  public startTenantEdit(tenant: UserTenant) : void {

    const dialogRef = this.dialog.open(EditUserTenantDialogComponent, {
      data: {
          tenantList: this.data.tenantList,
          roles: this.data.roles,
          user: this.data.user,
          tenant:tenant
         }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(result.data.tenants)
      this.dataSource.data = this.data.user.userTenants
    });
    return;
  }

  addNew() {
    const dialogRef = this.dialog.open(AddUserTenantDialogComponent, {
      autoFocus: false,
      data: {
          tenantList: this.data.tenantList,
          roles: this.data.roles,
          user: this.data.user,
         }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.dataSource.data = this.data.user.userTenants;   
    });
    return;
  }

  showEdit(row:UserTenant) {
    return true;
  }

  deleteTenant(row:UserTenant) {
    ///Show dialog are you sure you wish to remove Tenant from user.
    const dialogRef = this.dialog.open(DeleteUserTenantDialogComponent, {
      data: {
            user: this.data.user,
            tenant: row,
         }
    });

    dialogRef.afterClosed().subscribe(result => {
        this.dataSource.data = this.data.user.userTenants;   
    });
    return;
  }

  showDelete(row: UserTenant) {
    return true;
  }

  public confirmEdit(): void {
    if (this.userCredentials.userName !== this.data.user.userName || this.userCredentials.emailAddress !== this.data.user.emailAddress) {
      this.userService.updateUserCredentials(this.data.user);
    }
    this.userService.updateuser(this.data.user);
  }
}
