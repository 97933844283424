import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, Inject, OnInit } from '@angular/core';
import { UserService } from '../services/user.service';
import { FormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { Tenant } from '../models/tenant';
import { requiredSelection } from '../shared/validators';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-reassign-user.dialog',
  templateUrl: './reassignUser.dialog.component.html',
  styleUrls: ['./reassignUser.dialog.component.css']
})

export class ReasignUserDialogComponent implements OnInit {

  selectedTenant: string;
  selectedRoles: string[] = [];
  selectedTenants: Observable<Tenant[]>;

  constructor(public dialogRef: MatDialogRef<ReasignUserDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public userService: UserService) { }

  formControl = new FormControl('', [
    Validators.required
    // Validators.email,
  ]);
  tenantControl = new FormControl('', [
    requiredSelection
  ]);

  ngOnInit(): void {
    this.selectedRoles = [];
    this.selectedTenants = this.tenantControl.valueChanges.pipe(
      startWith(''),
      map(value => {
         return value && typeof value === 'string' ? this.filterTenants(value) : this.data.tenantList;
      }),
    );
  }

  getErrorMessage() {
    return  this.formControl.hasError('required') ? 'Required field' :
            this.formControl.hasError('email') ? 'Not a valid email' :
              '';
  }

  submit() {
    // emppty stuff
  }

  onNoClick(): void {
    this.data.user.tenantName = '';
    this.dialogRef.close();
  }

  displayTenantName(tenant?: Tenant): string | undefined {
    return tenant ? tenant.name : undefined;
  }

  filterTenants(value: string) { 
    let filter = value?.toLowerCase();
    let currValues: Tenant[] = this.data.tenantList;
    return currValues.filter(option => option.name.toLowerCase().includes(filter));
  }

  onRoleChange(event) {

    if (event.checked) {
      this.selectedRoles.push(event.source.value);
    } else {
      const index = this.selectedRoles.indexOf(event.source.value, 0);
      if (index > -1) {
        this.selectedRoles.splice(index, 1);
      }
    }
  }

  // event handler for the select element's change event
  onTenantChanged(event: any) {
    this.data.tenantId = event.option.value.id;
    this.data.tenantName = event.option.value.name;
  }

  public confirmReassign(): void {
    if (!this.data.tenantId) {
      return;
    }

    this.data.roles = this.selectedRoles;
    this.data.userId = this.data.user.userId;

    this.userService.reassignUser(this.data);
  }
}
