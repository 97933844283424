import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, Inject, OnInit } from '@angular/core';
import { UserService } from '../services/user.service';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { Tenant } from '../models/tenant';
import { map, startWith } from 'rxjs/operators';
import { requiredSelection } from '../shared/validators';

@Component({
  selector: 'app-add-user.dialog',
  templateUrl: './addUser.dialog.component.html',
  styleUrls: ['./addUser.dialog.component.css']
})

export class AddUserDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<AddUserDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public userService: UserService) { }

  newUser: any = {};
  selectedRoles: string[];
  selectedTenants: Observable<Tenant[]>;

  addUserForm : FormGroup;
  tenantControl = new FormControl('', [
    requiredSelection
  ]);

  ngOnInit(): void {
    this.selectedRoles = [];
    this.selectedTenants = this.tenantControl.valueChanges.pipe(
      startWith(''),
      map(value => {
         return value && typeof value === 'string' ? this.filterTenants(value) : this.data.tenantList;
      }),
    );

    this.addUserForm = new FormGroup({
      userName: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required]),
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl('', [Validators.required]),
      phoneNumber: new FormControl('', [Validators.pattern('^[0-9]{10}?$')])
    });
  }

  submit() {
    // emppty stuff
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  public confirmAdd(): void {
    this.newUser.roles = this.selectedRoles;
    this.newUser.sendRegistrationEmail = true;
    this.userService.adduser(this.newUser);
  }

  onTenantChanged(event: any) {
    this.newUser.tenantId = event.option.value.id;
  }

  displayTenantName(tenant?: Tenant): string | undefined {
    return tenant ? tenant.name : undefined;
  }

  filterTenants(value: string) { 
    let filter = value?.toLowerCase();
    let currValues: Tenant[] = this.data.tenantList;
    return currValues.filter(option => option.name.toLowerCase().includes(filter));
  }

  onRoleChange(event) {

    if (event.checked) {
      this.selectedRoles.push(event.source.value);
    } else {
      const index = this.selectedRoles.indexOf(event.source.value, 0);
      if (index > -1) {
        this.selectedRoles.splice(index, 1);
      }
    }
  }
}
