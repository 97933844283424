import { KeycloakService } from 'keycloak-angular';
import { environment } from '../../environments/environment';
import { ConfigService } from './config.service';
import { LoggingService } from './logging.service';

export function keycloakInitializer(
  keycloakService: KeycloakService,
  configService: ConfigService,
  loggingService: LoggingService,

  ): () => Promise<any> {

  return (): Promise<any> => {
    if (environment.ENV.includes(':mock')) {
      // In mock environment, set all to allowed
      // TODO: set tenant and other stuff
    } else {

      // TODO: not have to disable linting?
      // eslint-disable-next-line no-async-promise-executor, @typescript-eslint/no-misused-promises
      return new Promise<void>(async (resolve, reject) => {
        try {
          await keycloakService.init({
            config: {
              url: configService.keycloakUrl,
              realm: configService.keycloakRealm,
              clientId: configService.keycloakClientId,
            },
            loadUserProfileAtStartUp: false,
            initOptions: {
              onLoad: 'login-required',
              checkLoginIframe: true,
            },
            bearerExcludedUrls: [],
          });
          resolve();
        } catch (error) {
          reject(error);
        }
      });
    }
  };
}
