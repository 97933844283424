import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';

import { ConfigService } from '../utils/config.service';

import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard extends KeycloakAuthGuard {
    constructor
       (protected readonly router: Router,
         protected readonly keycloak: KeycloakService, private configService: ConfigService) {
        super(router, keycloak);
    }

    async isAccessAllowed(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        if (environment.ENV.includes(':mock')) {
            // In mock environment, set all to allowed
            return true;
        }

        // Force the user to log in if currently unauthenticated.
        if (!this.authenticated) {
            await this.keycloak.login({
                redirectUri: window.location.origin + state.url,
            });
        }

        // Ensure the user is an admin within the right client
        let clientId = this.configService.keycloakClientId;
        return this.keycloak.isUserInRole('admin', clientId);
    }
}
