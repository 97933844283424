import { Component, OnInit } from '@angular/core';

declare const $: any;
declare interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
}
export const ROUTES: RouteInfo[] = [
    { path: '/dashboard', title: 'Dashboard',  icon: 'dashboard', class: '' },
    { path: '/tenants', title: 'Enterprises',  icon: 'store', class: '' },
    { path: '/applications', title: 'Client Applications',  icon:'content_paste', class: '' },
    { path: '/users', title: 'Users',  icon:'person', class: '' },
];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  menuItems: any[];

  constructor() { }

  ngOnInit() : void {
    this.menuItems = ROUTES.filter(menuItem => menuItem);
  }
}
