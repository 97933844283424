import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ConfigService } from '../utils/config.service';
import { Dashboard, ChartItem } from '../models/dashboard';
import { PathService } from '../utils/path.service';
import { TenantDashboardInfo } from '../models/tenantDashboardInfo';

@Injectable()
export class DashboardService {

  // Define the routes we are going to interact with
  private url = this.path.Combine(this.configService.apiRootUrl, 'api/v0/dashboard/');

  constructor(
      private httpClient: HttpClient,
      private configService: ConfigService,
      private path: PathService) { }

  // Read Methods
  getData(): Observable<Dashboard> {
    const url = this.url;
    return this.httpClient.get<Dashboard>(url);
  }

  getEvents(days): Observable<any> {
    const url = this.path.Combine(this.url, 'userevents?days=' + days);
    return this.httpClient.get<any>(url);
  }

  getTopTenantsByUser(size): Observable<TenantDashboardInfo[]> {
    const url = this.path.Combine(this.url, 'tenantsbyusers?count=' + size);
    return this.httpClient.get<any>(url);
  }

  getTopTenantsByUnit(size): Observable<TenantDashboardInfo[]> {
    const url = this.path.Combine(this.url, 'tenantsbyunits?count=' + size);
    return this.httpClient.get<any>(url);
  }
}
