<div class="container">
  <h3 mat-dialog-title>Import Enterprises</h3>

  <form (ngSubmit)="submit" #formControl="ngForm">
    
    <div class="row">
      <div class="col-md-12">
        <mat-form-field style="width:100%;" (click)="fileUpload.click()">
          <button mat-icon-button matPrefix >
            <mat-icon>attach_file</mat-icon>
          </button>
          <input type="text" class="choose-file-input" placeholder="File to import" readonly matInput [formControl]="control" />
          <input type="file" hidden #fileUpload (change)="onFileSelected($event)" />
        </mat-form-field>
      </div>
    </div>

    <div mat-dialog-actions>
      <button mat-raised-button class="btn btn-danger pull-right" [type]="submit" [disabled]="file == null"
        [mat-dialog-close]="1" (click)="confirmImport()">Save</button>
      <button mat-button (click)="onNoClick()" tabindex="-1">Cancel</button>
    </div>
  </form>
</div>