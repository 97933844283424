import { Unit } from './unit';

export class Tenant {
    id: string;
    name: string;
    externalId: string;
    rak:string;
    units: Unit[];
    ipAddresses:string;
    allowedIpAddresses: string[];
    isADEnabled: boolean;
    cloudId: string;
  }
