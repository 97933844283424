export class Unit {
    id: string;
    name: string;
    secondaryName: string;
    externalId: string;
    rak:string;
    tenantId: string;
    ncpdpIds:string[];
    npis:string[];
    dea:string;
}