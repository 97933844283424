import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {MatDialog} from '@angular/material/dialog';
import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {TenantService} from '../services/tenant.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import { Tenant } from '../models/tenant';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-edit-tenant.dialog',
  templateUrl: './editTenant.dialog.component.html',
  styleUrls: ['./editTenant.dialog.component.css']
})

export class EditTenantDialogComponent implements OnInit, OnDestroy  {
  tenant : Tenant;
  editTenantForm : FormGroup;
  private destory$ = new Subject<void>();

  get ipAddresses(){
    return this.tenant?.allowedIpAddresses?.join(",");
  }
  set ipAddresses(value: string){
    this.tenant.ipAddresses =  value;
  }

  constructor(public dialogRef: MatDialogRef<EditTenantDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public dialog: MatDialog,
              public tenantService: TenantService) { }

  ngOnInit(): void {
    this.tenant = this.data;
    this.tenant.ipAddresses = this.tenant?.allowedIpAddresses?.join(',');

    this.editTenantForm = new FormGroup({
      name: new FormControl(this.tenant.name,[Validators.required]),
      rak: new FormControl({value: this.tenant.rak}, [Validators.required]),
      externalId: new FormControl({value: this.tenant.externalId},),
      allowedIps: new FormControl(this.ipAddresses, [Validators.pattern('^[0-9///./,/-]*$')]),
      isADEnabled: new FormControl(this.tenant.isADEnabled),
      cloudId: new FormControl(this.tenant.cloudId),
    });

    this.editTenantForm.get('isADEnabled').valueChanges.pipe(takeUntil(this.destory$))
    .subscribe(value => {
      if(value) {
        this.editTenantForm.get('cloudId').setValidators(Validators.required);
        this.editTenantForm.get('cloudId').setErrors({'incorrect': true});
      } else {
        this.editTenantForm.get('cloudId').clearValidators();
        this.editTenantForm.get('cloudId').setErrors(null);
      }});
  }

  submit() {
  // emppty stuff
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  public confirmEdit(): void {
    this.data.allowedIpAddresses = this.data.ipAddresses?.split(',').filter(n => n) ?? [];     
    this.tenant.name = this.data.name;

    this.tenantService.editTenant(this.tenant);
  }
  
  ngOnDestroy(): void {
    this.destory$.next();
    this.destory$.complete();
}
}
