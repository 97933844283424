import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { User } from '../models/user';
import { PagedSearchResult } from '../models/pagedSearchResult';
import { ToasterService } from './toaster.service';
import { ConfigService } from '../utils/config.service';
import { PathService } from '../utils/path.service';
import { UserRole } from '../models/userRole';

@Injectable()
export class UserService {

  // Define the routes we are going to interact with
  private usersUrl = this.path.Combine(this.configService.apiRootUrl, 'api/v0/users/');
  private rolesUrl = this.path.Combine(this.configService.apiRootUrl, 'api/v0/userroles/');

  dataChange: BehaviorSubject<User[]> = new BehaviorSubject<User[]>([]);

  // Temporarily stores data from dialogs
  dialogData: any;

  constructor(private httpClient: HttpClient,
      private configService: ConfigService,
      private toasterService: ToasterService, private path: PathService) { }

  get data(): User[] {
    return this.dataChange.value;
  }

  getDialogData() {
    return this.dialogData;
  }

  // Read Methods
  getAll(): void {

    // TODO: Fix this for proper server side paging
    var url = this.usersUrl + '?pagesize=10000';
    this.httpClient.get<PagedSearchResult>(url).subscribe(data => {

      // data is a PagedUserSearchResult
      this.dataChange.next(data.items);
    },
      (err: HttpErrorResponse) => {
        this.toasterService.error(err.name, err.message, "Unable to retrieve users");
      });
  }

  getuser(id: String): Observable<User> {
    var url = this.usersUrl + id;
    return this.httpClient.get<User>(url);
  }

  getRoles() :Observable<UserRole[]> {
      var url = this.rolesUrl;
      return this.httpClient.get<UserRole[]>(url);
  }

  // Create Methods
  adduser(user: User): void {
    var url = this.usersUrl;
    this.httpClient.post(url, user).subscribe(data => {
      this.dialogData = user;
      this.toasterService.success("User " + user.userName + " Added or reassigned.");
    },
      (err: HttpErrorResponse) => {

        if (err.status == 409){

          var array = err.error;

          var errorMessage = "";
          if (array.includes("Email Address") ){
            errorMessage += "Email address is already in use. ";
          }
          if (array.includes("UserName") ){
            errorMessage += "UserName is already in use. ";
          }
          if (array.includes("PhoneNumber") ){
            errorMessage += "PhoneNumber is already in use";
          }

          this.toasterService.error("Conflict", errorMessage, "Unable to add User " + user.userName);
        }
        else {
          this.toasterService.error(err.name, err.message, "Unable to add User " + user.userName);
        }
      });
  }

  updateuser(user: User): void {
    var url = this.usersUrl + user.id;
    this.httpClient.put(url, user).subscribe(data => {
      this.dialogData = user;
      this.toasterService.success("User " + user.userName + " Profile Updated");
    },
      (err: HttpErrorResponse) => {
        this.toasterService.error(err.name, err.message, "Unable to update User " + user.userName);
      }
    );
  }

  updateUserCredentials(user: User): void {
    var url = this.usersUrl + user.id + "/credentials";
    this.httpClient.put(url, user).subscribe(data => {
      this.dialogData = user;
      this.toasterService.success("User " + user.userName + " Credentials Updated");
    },
      (err: HttpErrorResponse) => {
        this.toasterService.friendlyError(err.statusText, err.error, "Unable to update User credentials");
      }
    );
  }

  reassignUser(request: any): void {
    var url = this.usersUrl + 'reassign';

    var payload = {
      userId: request.user.id,
      tenantId: request.tenantId,
      roles: request.roles
    };

    this.httpClient.put(url, payload).subscribe(data => {
      this.dialogData = data;
      this.toasterService.success("User " + request.user.userName + " now assigned to enterprise " + request.tenantName);
    },
      (err: HttpErrorResponse) => {
        this.toasterService.error(err.name, err.message, "Unable to reassign User " + request.user.userName);
      }
    );
  }

  deleteuser(user: User): void {
    var url = this.usersUrl + user.id;
    this.httpClient.delete(url).subscribe(data => {
      this.dialogData = user;
      this.toasterService.success("User " + user.userName + " Terminated");
    },
      (err: HttpErrorResponse) => {
        this.toasterService.error(err.name, err.message, "Unable to remove User " + user.userName);
      }
    );
  }
}
