import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {MatDialog} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {DataSource} from '@angular/cdk/collections';
import {BehaviorSubject, fromEvent, merge, Observable, Subject} from 'rxjs';
import {map, takeUntil} from 'rxjs/operators';
import { AddUserDialogComponent } from './addUser.dialog.component';

import {UserService } from '../services/user.service';
import {TenantService} from '../services/tenant.service';
import {User} from '../models/user';
import {Tenant } from '../models/tenant';
import { EditUserDialogComponent } from './editUser.dialog.component';
import { DeleteUserDialogComponent } from './deleteUser.dialog.component';

import { ToasterService } from '../services/toaster.service';
import { ReasignUserDialogComponent } from './reassignUser.dialog.component';
import { ConfigService } from '../utils/config.service';
import { PathService } from '../utils/path.service';

import { UserRole } from '../models/userRole';

@Component({
  selector: 'app-users',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent implements OnInit, OnDestroy {

  displayedColumns = ['userName', 'emailAddress', 'lastName', 'firstName', 'tenantName', 'status',  'actions'];
  exampleDatabase: UserService | null;
  dataSource: ExampleDataSource | null;
  index: number;
  id: string;
  tenants: Tenant[];
  roles: Observable<UserRole[]>;

  destroy$ = new Subject<void>();

  constructor(public httpClient: HttpClient,
              public dialog: MatDialog,
              public dataService: UserService,
              public tenantService: TenantService,
              private configService: ConfigService,
              private toasterService: ToasterService,
              private path: PathService
              ) {}

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild('filter',  {static: true}) filter: ElementRef;

  ngOnInit() {
    this.loadData();
  }

  refresh() {
    this.loadData();
  }

  showEdit(user: User){
    return !user.isTerminated;
  }

  showDelete(user: User){
    return !user.isTerminated;
  }


  addNew() {

    const user = new User();
    user.roles = [];

    const dialogRef = this.dialog.open(AddUserDialogComponent, {
      autoFocus: false,
      data: {
            user,
            tenantList: this.tenants,
            roles: this.roles
          }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 1) {
        // After dialog is closed we're doing frontend updates
        // For add we're just pushing a new row inside DataService
        this.exampleDatabase.dataChange.value.push(this.dataService.getDialogData());
        this.refreshTable();
      }
    });

    // And lastly refresh table
    this.refreshTable();
  }

   startEdit(user: User) {

    const dialogRef = this.dialog.open(EditUserDialogComponent, {
      data: {
          user,
          roles: this.roles,
          tenantList: this.tenants,
         }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 1) {
        // When using an edit things are little different, firstly we find record inside DataService by id
        const foundIndex = this.exampleDatabase.dataChange.value.findIndex(x => x.id === this.id);
        // Then you update that record using data from dialogData (values you enetered)
        this.exampleDatabase.dataChange.value[foundIndex] = this.dataService.getDialogData();
      }
      // And lastly refresh table
      this.refreshTable();
    });
  }

  delete(user: User){
    const dialogRef = this.dialog.open(DeleteUserDialogComponent, {
      data: {user }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 1) {
        user.isTerminated = true;
        const foundIndex = this.exampleDatabase.dataChange.value.findIndex(x => x.id === this.id);
        this.exampleDatabase.dataChange.value[foundIndex] = this.dataService.getDialogData();
      }
      // And lastly refresh table
      this.refreshTable();
    });
  }

  reassignUser(user: User){
    const dialogRef = this.dialog.open(ReasignUserDialogComponent, {
      autoFocus: false,
      data: {
        user,
        tenantList: this.tenants,
        roles: this.roles
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 1) {
        // When using an edit things are little different, firstly we find record inside DataService by id
        const foundIndex = this.exampleDatabase.dataChange.value.findIndex(x => x.id === this.id);
        // Then you update that record using data from dialogData (values you enetered)
        this.exampleDatabase.dataChange.value[foundIndex] = this.dataService.getDialogData();
      }
      // And lastly refresh table
      this.refreshTable();

    });
  }

  private refreshTable() {
    // Refreshing table using paginator
    // Thanks yeager-j for tips
    // https://github.com/marinantonio/angular-mat-table-crud/issues/12
    this.paginator._changePageSize(this.paginator.pageSize);
    this.loadData();
  }

  public loadData() {

    this.roles = this.dataService.getRoles();
    this.tenantService.getTenants().pipe(takeUntil(this.destroy$)).subscribe(x => this.tenants = x);

    this.exampleDatabase = new UserService(this.httpClient, this.configService, this.toasterService, this.path);

    this.dataSource = new ExampleDataSource(this.dataService, this.paginator, this.sort);
    fromEvent(this.filter.nativeElement, 'keyup')
      // .debounceTime(150)
      // .distinctUntilChanged()
      .subscribe(() => {
        if (!this.dataSource) {
          return;
        }
        this.dataSource.filter = this.filter.nativeElement.value;
      });
  }

  getTenantDetails(user: User) {
    let displayTenant :string = '';
    user.userTenants.forEach( x => displayTenant += x.tenantName + ":" + x.roles.join() + '\n');

    return displayTenant;
  }

  ngOnDestroy(): void{
    this.destroy$.next();
    this.destroy$.complete();
  }
}


export class ExampleDataSource extends DataSource<User> {
  _filterChange = new BehaviorSubject('');

  get filter(): string {
    return this._filterChange.value;
  }

  set filter(filter: string) {
    this._filterChange.next(filter);
  }

  filteredData: User[] = [];
  renderedData: User[] = [];

  constructor(public _exampleDatabase: UserService,
              public _paginator: MatPaginator,
              public _sort: MatSort) {
    super();
    // Reset to the first page when the user changes the filter.
    this._filterChange.subscribe(() => this._paginator.pageIndex = 0);
  }

  /** Connect function called by the table to retrieve one stream containing the data to render. */
  connect(): Observable<User[]> {
    // Listen for any changes in the base data, sorting, filtering, or pagination
      const displayDataChanges = [
      this._exampleDatabase.dataChange,
      this._sort.sortChange,
      this._filterChange,
      this._paginator.page
    ];

      this._exampleDatabase.getAll();


      return merge(...displayDataChanges).pipe(map( () => {
        // Filter data
        this.filteredData = this._exampleDatabase.data.slice().filter((user: User) => {
          const searchStr = (user.id + user.lastName + user.firstName + user.tenantName + user.emailAddress + user.userName).toLowerCase();
          return searchStr.indexOf(this.filter.toLowerCase()) !== -1;
        });

        // Sort filtered data
        const sortedData = this.sortData(this.filteredData.slice());

        // Grab the page's slice of the filtered sorted data.
        const startIndex = this._paginator.pageIndex * this._paginator.pageSize;
        this.renderedData = sortedData.splice(startIndex, this._paginator.pageSize);
        return this.renderedData;
      }
    ));
  }

  disconnect() {}


  /** Returns a sorted copy of the database data. */
  sortData(data: User[]): User[] {
    if (!this._sort.active || this._sort.direction === '') {
      return data;
    }

    return data.sort((a, b) => {
      let propertyA: number | string = '';
      let propertyB: number | string = '';

      switch (this._sort.active) {
        case 'id': [propertyA, propertyB] = [a.id, b.id]; break;
        case 'userName': [propertyA, propertyB] = [a.userName, b.userName]; break;
        case 'emailAddress': [propertyA, propertyB] = [a.emailAddress, b.emailAddress]; break;

        case 'firstName': [propertyA, propertyB] = [a.firstName, b.firstName]; break;
        case 'lastName': [propertyA, propertyB] = [a.lastName, b.lastName]; break;
        case 'tenantName': [propertyA, propertyB] = [a.tenantName, b.tenantName]; break;
        case 'status': [propertyA, propertyB] = [(a.isActive ? 1 : 0) + (a.isTerminated ? 2 : 0), (b.isActive ? 2 : 0) + (b.isTerminated ? 1 : 0)]; break;
        // case 'status': [propertyA, propertyB] = [a.getStatus(), b.getStatus()]; break;
        // case 'isActive': [propertyA, propertyB] = [a.isActive, b.isActive]; break;
      }

      const valueA = isNaN(+propertyA) ? (propertyA as string)?.toLowerCase() : +propertyA;
      const valueB = isNaN(+propertyB) ? (propertyB as string)?.toLowerCase() : +propertyB;

      return (valueA < valueB ? -1 : 1) * (this._sort.direction === 'asc' ? 1 : -1);
    });
  }
}
