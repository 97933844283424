<div class="logo">
    <a href="" class="simple-text">
        <div class="logo-img">
            <img src="/assets/img/saillogo.png" />
        </div>
        CIS Admin
    </a>
</div>
<div class="sidebar-wrapper">
    <ul class="nav">
        <li routerLinkActive="active" *ngFor="let menuItem of menuItems" class="{{menuItem.class}} nav-item">
            <a class="nav-link" [routerLink]="[menuItem.path]">
                <i class="material-icons">{{menuItem.icon}}</i>
                <p>{{menuItem.title}}</p>
            </a>
        </li>

        
    </ul>
</div>